<template>
    <div
        class="text-gray-500 m-0 font-sans antialiased font-normal text-left bg-white leading-default text-base overflow-y-hidden  w-screen ">
        <div ref="fixedSection" v-if="width > 1030" class="w-10/12  mx-auto  top-0 z-110 ">
            <nav :class="{'opacity-30': isTransparent }"
                  class="mt-2 hover:opacity-100 transition-all fixed justify-center w-10/12  rounded-lg mx-auto text-center z-30 px-6 py-2  shadow-xl bg-white/80 backdrop-blur-2xl backdrop-saturate-200 rounded-blur lg:flex-nowrap lg:justify-center">
                <div class="container flex flex-wrap items-center justify-between lg-max:overflow-hidden lg:flex-nowrap">
                    <router-link class="py-2.5 text-sm mr-4 ml-4 whitespace-nowrap font-bold text-slate-700 lg:ml-0"
                        to="/index.html">Проектный парк ФТФ</router-link>
                    <button aria-expanded="false" nav-collapse-trigger=""
                        class="px-3 py-1 ml-2 leading-none bg-transparent rounded-lg shadow-none cursor-pointer lg:hidden text-lg text-slate-700">
                        <span class="inline-block mt-2 align-middle w-6 h-6 bg-none">
                            <span bar1=""
                                class="block relative w-5.5 h-px rounded-px bg-slate-700 transition-all duration-200 mx-auto my-0 origin-10-10"></span>
                            <span bar2=""
                                class="block relative w-5.5 h-px rounded-px bg-slate-700 transition-all duration-200 mx-auto my-0 mt-1.75"></span>
                            <span bar3=""
                                class="block relative w-5.5 h-px rounded-px bg-slate-700 transition-all duration-200 mx-auto my-0 mt-1.75 origin-10-90"></span>
                        </span>
                    </button>
                    <div
                        class="items-center w-full pt-4 pb-2 transition-all duration-350 ease-soft-in-out lg-max:max-h-0 lg-max:overflow-hidden lg-max:hidden lg-max:bg-transparent lg-max:shadow-none md-max:relative grow basis-full rounded-2xl lg:flex lg:basis-auto lg:py-0">

                        <ul class="flex flex-col pl-0 mx-auto mb-0 list-none md-max:w-full lg:flex-row">
                            <li v-if="showKanbanVoard()"
                                class="relative mx-2 md-max:static group lg:after:content-[''] lg:after:top-0 lg:hover:after:top-full lg:after:absolute lg:after:left-0 lg:after:-bottom-6 lg:after:w-full lg:after:h-full">
                                <router-link to="/Board/23"
                                    class="flex items-center hover:underline justify-between px-4 py-2 pl-2 transition-all cursor-pointer select-none font-bold text-red-700 lg-max:text-slate-700 ease-soft-in-out lg:px-2 text-sm">
                                    Трекер задач ПП

                                </router-link>
                            </li>
                            <li
                                class="relative mx-2 md-max:static group lg:after:content-[''] lg:after:top-0 lg:hover:after:top-full lg:after:absolute lg:after:left-0 lg:after:-bottom-6 lg:after:w-full lg:after:h-full">
                                <router-link to="/Offers/ActiveOffer"
                                    class="flex items-center hover:underline justify-between px-4 py-2 pl-2 font-normal transition-all cursor-pointer select-none text-slate-700 lg-max:text-slate-700 ease-soft-in-out lg:px-2 text-sm">
                                    Активные вакансии

                                </router-link>
                            </li>
                            <li
                                class="relative mx-2 md-max:static group lg:after:content-[''] lg:after:top-0 lg:hover:after:top-full lg:after:absolute lg:after:left-0 lg:after:-bottom-6 lg:after:w-full lg:after:h-full">
                                <a aria-expanded="false" nav-dropdown-trigger="applications" role="button"
                                    class="flex items-center justify-between px-4 py-2 pl-2 font-normal transition-all cursor-pointer select-none text-slate-700 lg-max:text-slate-700 ease-soft-in-out lg:px-2 text-sm">
                                    Ближайшие мероприятия
                                    <img src="@/assets/img/down-arrow-dark.svg" alt="down-arrow"
                                        class="hidden ml-1 lg:block">
                                    <img src="@/assets/img/down-arrow-dark.svg" alt="down-arrow"
                                        class="block ml-1 lg:hidden">
                                </a>
                                

                                <div nav-dropdown="applications"
                                    class="shadow-lg sm-max:right-auto md-max:left-0 md-max:right-0 lg-max:bg-transparent lg-max:overflow-scroll lg-max:relative lg-max:shadow-none lg-max:transform-none lg-max:block lg-max:h-0 lg-max:transition-all lg-max:duration-350 lg-max:ease-soft lg-max:pt-0 lg-max:pb-0 lg-max:opacity-0 lg-max:top-0 lg-max:origin-top lg-max:pointer-events-none  static m-0 mt-0 text-left list-none bg-white rounded-2xl lg:mt-4 lg:min-w-160 lg:absolute z-100 text-sm text-slate-500 bg-clip-padding lg:shadow-soft-3xl lg:cursor-pointer lg:transition-all lg:duration-250 lg:block lg:group-focus:pointer-events-auto lg:group-focus:transform-dropdown-show lg:group-focus:opacity-100 lg:group-focus:before:-top-5 lg:group-hover:opacity-100 lg:opacity-0  lg:origin-top lg:group-hover:pointer-events-auto lg:group-hover:transform-dropdown-show lg:pointer-events-none lg:transform-dropdown lg:before:font-awesome lg:before:content-['\f0d8'] lg:before:absolute lg:before:top-0 lg:group-hover:before:-top-4.8  lg:before:left-7 lg:before:right-auto lg:before:text-5.5 lg:before:text-white lg:before:transition-all lg:before:duration-350 lg:before:ease-soft">
                                    <div class="w-full max-w-full ">
                                        <div
                                            class="relative flex flex-col min-w-0 break-words bg-white border-0  shadow-soft-xl rounded-2xl bg-clip-border">
                                            <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-4 pb-0">
                                                <h6 class="mb-0  whitespace-nowrap">Ближайшие мероприятия
                                                </h6>

                                            </div>
                                            <div class="flex-auto p-4 rounded-xl">
                                                <div class="flex">
                                                    <div>
                                                        <div
                                                            class="shadow-lg inline-block w-12 h-12 text-center text-black bg-center rounded-lg shadow-none fill-current stroke-none bg-blue-600/5">
                                                            <img class="h-12 w-12 p-1 my-auto object-fit mx-auto"
                                                                src="@/assets/img/ForPage/billboard.png" alt="">

                                                        </div>
                                                    </div>
                                                    <div class="ml-4">
                                                        <div class="">
                                                            <h6 class="mb-1 leading-normal text-sm text-slate-700">
                                                                Cyber Week</h6>
                                                            <span class="leading-normal text-sm whitespace-nowrap">27
                                                                March 2020, at 12:30
                                                                PM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex mt-6">
                                                    <div>
                                                        <div
                                                            class="shadow-lg inline-block w-12 h-12 text-center text-black bg-center rounded-lg shadow-none fill-current stroke-none bg-blue-600/5">
                                                            <img class="h-12 w-12 p-1 mx-auto my-auto rounded-lg "
                                                                src="@/assets/img/ForPage/calendar.png" alt="">
                                                        </div>
                                                    </div>
                                                    <div class="ml-4">
                                                        <div class="">
                                                            <h6 class="mb-1 leading-normal  text-sm text-slate-700">
                                                                Meeting with Marry</h6>
                                                            <span class="leading-normal text-sm">24 March 2021, at 10:00
                                                                PM</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li
                                class="relative mx-2 md-max:static group lg:after:content-[''] lg:after:top-0 lg:hover:after:top-full lg:after:absolute lg:after:left-0 lg:after:-bottom-6 lg:after:w-full lg:after:h-full">
                                <a aria-expanded="false" nav-dropdown-trigger="docs" role="button"
                                    class="flex items-center justify-between px-4 py-2 pl-2 font-normal transition-all cursor-pointer select-none text-slate-700 lg-max:text-slate-700 ease-soft-in-out lg:px-2 text-sm">
                                    Навигация по сайту
                                    <img src="@/assets/img/down-arrow-dark.svg" alt="down-arrow"
                                        class="hidden ml-1 lg:block">
                                    <img src="@/assets/img/down-arrow-dark.svg" alt="down-arrow"
                                        class="block ml-1 lg:hidden">
                                </a>
                                <div
                                    class="py-4 px-2 shadow-lg sm-max:right-auto md-max:left-0 md-max:right-0 lg-max:bg-transparent lg-max:overflow-scroll lg-max:relative lg-max:shadow-none lg-max:transform-none lg-max:block lg-max:h-0 lg-max:transition-all lg-max:duration-350 lg-max:ease-soft lg-max:pt-0 lg-max:pb-0 lg-max:opacity-0 lg-max:top-0 lg-max:origin-top lg-max:pointer-events-none  static m-0 mt-0 text-left list-none bg-white rounded-2xl lg:mt-4 lg:min-w-160 lg:absolute z-100 text-sm text-slate-500 bg-clip-padding lg:shadow-soft-3xl lg:cursor-pointer lg:transition-all lg:duration-250 lg:block lg:group-focus:pointer-events-auto lg:group-focus:transform-dropdown-show lg:group-focus:opacity-100 lg:group-focus:before:-top-5 lg:group-hover:opacity-100 lg:opacity-0  lg:origin-top lg:group-hover:pointer-events-auto lg:group-hover:transform-dropdown-show lg:pointer-events-none lg:transform-dropdown lg:before:font-awesome lg:before:content-['\f0d8'] lg:before:absolute lg:before:top-0 lg:group-hover:before:-top-4.8  lg:before:left-7 lg:before:right-auto lg:before:text-5.5 lg:before:text-white lg:before:transition-all lg:before:duration-350 lg:before:ease-soft">
                                    <div class="hidden lg:block">
                                        <ul class="flex flex-col pl-0 mx-auto mb-0 list-none">
                                            <li class="relative block p-0 bg-white border-0 rounded-t-lg">
                                                <router-link to="/Project/Index"
                                                    class="block clear-both w-full px-4 py-2 font-normal bg-transparent border-0 rounded-lg hover:text-slate-700 hover:bg-gray-200 text-slate-500 text-align-inherit whitespace-nowrap lg:transition-colors lg:duration-300 lg:ease-soft">
                                                    <div class="flex">
                                                        <div
                                                            class="flex mt-1 mr-4 text-black fill-current h-4 w-4 stroke-none">
                                                            <svg class="text-slate-400" width="16px" height="16px"
                                                                viewBox="0 0 40 40" version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                <title>spaceship</title>
                                                                <g stroke="none" stroke-width="1" fill="none"
                                                                    fill-rule="evenodd">
                                                                    <g transform="translate(-1720.000000, -592.000000)"
                                                                        fill="#FFFFFF" fill-rule="nonzero">
                                                                        <g transform="translate(1716.000000, 291.000000)">
                                                                            <g transform="translate(4.000000, 301.000000)">
                                                                                <path class="fill-slate-300"
                                                                                    d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z">
                                                                                </path>
                                                                                <path class="fill-slate-300"
                                                                                    d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z">
                                                                                </path>
                                                                                <path class="fill-slate-300"
                                                                                    d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z"
                                                                                    opacity="0.598539807">
                                                                                </path>
                                                                                <path class="fill-slate-300"
                                                                                    d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z"
                                                                                    opacity="0.598539807">
                                                                                </path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <h6
                                                                class="flex p-0 mb-0 font-bold text-sm whitespace-nowrap text-slate-700">
                                                                Проекты</h6>
                                                            <span class="leading-normal text-sm">All about
                                                                overview, quick start, license and
                                                                contents</span>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </li>
                                            <li class="relative block p-0 bg-white border-0">
                                                <router-link to="/Users/Index"
                                                    class="block clear-both w-full px-4 py-2 font-normal bg-transparent border-0 rounded-lg hover:text-slate-700 hover:bg-gray-200 text-slate-500 text-align-inherit whitespace-nowrap lg:transition-colors lg:duration-300 lg:ease-soft">
                                                    <div class="flex">
                                                        <div
                                                            class="flex mt-1 mr-4 text-black fill-current h-4 w-4 stroke-none">
                                                            <svg class="text-slate-400" width="16px" height="16px"
                                                                viewBox="0 0 40 44" version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                <title>document</title>
                                                                <g stroke="none" stroke-width="1" fill="none"
                                                                    fill-rule="evenodd">
                                                                    <g transform="translate(-1870.000000, -591.000000)"
                                                                        fill="#FFFFFF" fill-rule="nonzero">
                                                                        <g transform="translate(1716.000000, 291.000000)">
                                                                            <g
                                                                                transform="translate(154.000000, 300.000000)">
                                                                                <path class="fill-slate-300"
                                                                                    d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                                                                                    opacity="0.603585379">
                                                                                </path>
                                                                                <path class="fill-slate-300"
                                                                                    d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z">
                                                                                </path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <h6
                                                                class="flex p-0 mb-0 font-bold text-sm whitespace-nowrap text-slate-700">
                                                                Участники</h6>
                                                            <span class="leading-normal text-sm">See our colors,
                                                                icons and typography</span>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </li>
                                            <li class="relative block p-0 bg-white border-0">
                                                <router-link to="/News/Index"
                                                    class="block clear-both w-full px-4 py-2 font-normal bg-transparent border-0 rounded-lg hover:text-slate-700 hover:bg-gray-200 text-slate-500 text-align-inherit whitespace-nowrap lg:transition-colors lg:duration-300 lg:ease-soft">
                                                    <div class="flex">
                                                        <div
                                                            class="flex mt-1 mr-4 text-black fill-current h-4 w-4 stroke-none">
                                                            <svg class="text-slate-400" width="16px" height="16px"
                                                                viewBox="0 0 42 42" version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                <title>box-3d-50</title>
                                                                <g stroke="none" stroke-width="1" fill="none"
                                                                    fill-rule="evenodd">
                                                                    <g transform="translate(-2319.000000, -291.000000)"
                                                                        fill="#FFFFFF" fill-rule="nonzero">
                                                                        <g transform="translate(1716.000000, 291.000000)">
                                                                            <g transform="translate(603.000000, 0.000000)">
                                                                                <path class="fill-slate-300"
                                                                                    d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z">
                                                                                </path>
                                                                                <path class="fill-slate-300"
                                                                                    d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                                                                                    opacity="0.7"></path>
                                                                                <path class="fill-slate-300"
                                                                                    d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                                                                                    opacity="0.7"></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <h6
                                                                class="flex p-0 mb-0 font-bold text-sm whitespace-nowrap text-slate-700">
                                                                Новости</h6>
                                                            <span class="leading-normal text-sm">Explore our
                                                                collection of fully designed components</span>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </li>
                
                                        </ul>
                                    </div>

                                </div>
                            </li>
                        </ul>
                        <ul class="hidden pl-0 mb-0 list-none lg:block lg:flex-row">

                            <li
                                class="relative mx-2 md-max:static group lg:after:content-[''] lg:after:top-0 lg:hover:after:top-full lg:after:absolute lg:after:left-0 lg:after:-bottom-6 lg:after:w-full lg:after:h-full">
                                <div v-if="this.LogginUser?.id != null">
                                    <router-link :to="'User/Details/' + this.LogginUser?.id"
                                        class="flex shrink-0 items-center text-start">
                                        <img v-if="!LogginUser.photo" alt="Man"
                                            src="@/components/Resourse/img/user.png"
                                            class="h-10 w-10 rounded-full object-cover" />
                                        <img v-else alt="Man"
                                            :src="'https://pp.ftf.tsu.ru/'+LogginUser?.photo?.linkFile"
                                            class="h-10 w-10 rounded-full object-cover" />
                                        <div class="ml-1.5">
                                            <p class="text-xs">
                                                <strong class="block font-medium">{{ LogginUser.firstName + ' ' +LogginUser.lastName }}</strong>

                                                <span> {{ LogginUser.email }} </span>
                                            </p>
                                        </div>
                                    </router-link>
                                    <div nav-dropdown="authentication"
                                        class=" lg:group-hover:pointer-events-auto lg:group-hover:opacity-100   static m-0 mt-0 text-left list-none bg-white rounded-xl lg:mt-4 lg:min-w-60 lg:absolute z-100 text-sm text-slate-500 bg-clip-padding  lg:block lg:opacity-0  ">
                                        <ul class="px-4 py-4">
                                            <li nav-nested-menu-trigger="" class="pb-4 ">
                                                <router-link :to="'/Users/Details/'+LogginUser.id"
                                                    class="hover:scale-105 block px-2 font-semibold transition-all ease-nav-brand text-sm text-slate-500 ">
                                                    <i class="fa fa-route sm:mr-1 pr-1 my-auto" aria-hidden="true"></i>
                                                    <span class="hidden sm:inline">В профиль</span>
                                                </router-link>

                                            </li>
                                            <li nav-nested-menu-trigger="" class="pb-4 ">
                                                <router-link :to="'/Users/Details/'+LogginUser.id"
                                                    class="hover:scale-105 block px-2 font-semibold transition-all ease-nav-brand text-sm text-slate-500 ">
                                                    <i class="fa fa-book sm:mr-1 pr-1 my-auto" aria-hidden="true"></i>
                                                    <span class="hidden sm:inline">Мои проекты</span>
                                                </router-link>

                                            </li>
                                            <li nav-nested-menu-trigger="">
                                                <a @click="this.Logout()"
                                                    class=" hover:scale-105 px-2 flex hover:cursor-pointer font-semibold transition-all ease-nav-brand text-sm text-slate-500 ">
                                                    <i class="fa fa-user my-auto sm:mr-1 pr-1" aria-hidden="true"></i>

                                                    <span class="hidden sm:inline">Выйти</span>
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>

                                <div v-else class="flex">
                                    <router-link to="/Login"
                                        class="block px-2 py-2 font-semibold transition-all ease-nav-brand text-sm text-slate-500 ">
                                        <i class="fa fa-user sm:mr-1 pr-1" aria-hidden="true"></i>
                                        <span class="hidden sm:inline">Войти</span>
                                    </router-link>

                                </div>

                            </li>



                        </ul>
                    </div>
                </div>
            </nav>
        </div>




        <div v-if="width <= 1030" class="fixed right-5 top-5 z-20 text-white justify-start flex-1 ">
            <div class=" bg-transparent  mr-5">
                <div class="relative  flex justify-end">
                    <button class="text-gray-500 w-10 h-10 shadow-lg  relative focus:outline-none bg-white"
                        @click="openMenu = !openMenu">
                        <div class="block w-5 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
                            <span aria-hidden="true"
                                class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
                                :class="{ 'rotate-45': openMenu, ' -translate-y-1.5': !openMenu }"></span>
                            <span aria-hidden="true"
                                class="block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out"
                                :class="{ 'opacity-0': openMenu }"></span>
                            <span aria-hidden="true"
                                class="block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out"
                                :class="{ '-rotate-45': openMenu, ' translate-y-1.5': !openMenu }"></span>
                        </div>
                    </button>
                </div>
            </div>


            <div v-if="openMenu" class="z-50 w-full  bg-neutral-focus rounded-lg shadow-lg  ">
                <div class="relative justify-center group w-full">
                    <div
                        class="py-4 px-2 sm-max:right-auto md-max:left-0 md-max:right-0 lg-max:bg-transparent lg-max:overflow-scroll relative shadow-none lg-max:transform-none lg-max:block lg-max:h-0 lg-max:transition-all lg-max:duration-350 lg-max:ease-soft lg-max:pt-0 lg-max:pb-0 lg-max:opacity-0 lg-max:top-0 lg-max:origin-top lg-max:pointer-events-none  static m-0 mt-0 text-left list-none bg-white rounded-2xl lg:mt-4 lg:min-w-160 lg:absolute z-100 text-sm text-slate-500 bg-clip-padding lg:shadow-soft-3xl lg:cursor-pointer lg:transition-all lg:duration-250 lg:block lg:group-focus:pointer-events-auto lg:group-focus:transform-dropdown-show lg:group-focus:opacity-100 lg:group-focus:before:-top-5 lg:group-hover:opacity-100 lg:opacity-0  lg:origin-top lg:group-hover:pointer-events-auto lg:group-hover:transform-dropdown-show lg:pointer-events-none lg:transform-dropdown lg:before:font-awesome lg:before:content-['\f0d8'] lg:before:absolute lg:before:top-0 lg:group-hover:before:-top-4.8  lg:before:left-7 lg:before:right-auto lg:before:text-5.5 lg:before:text-white lg:before:transition-all lg:before:duration-350 before:ease-soft">
                        <div class="pt-0 pb-4 ">
                            <router-link @click="openMenu = false"
                                class="py-2.5  text-sm mr-4 ml-2  whitespace-nowrap font-bold text-slate-700 lg:ml-0"
                                to="/index.html">Проектный парк ФТФ</router-link>
                        </div>

                        <details class="group ">
                            <summary
                                class="flex cursor-pointer items-center justify-between rounded-lg px-2 whitespace-nowrap">
                                <h2 class=" font-medium text-gray-900">
                                    Навигация по сайту
                                </h2>

                                <svg class="ml-1.5 h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-180"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M19 9l-7 7-7-7" />
                                </svg>
                            </summary>
                            <ul class="flex flex-col pt-2 pl-0 mx-auto mb-0 list-none">
                                <li class="relative block p-0 bg-white border-0 rounded-t-lg">
                                    <router-link to="/Project/Index" @click="openMenu = false"
                                        class="block clear-both w-full px-4 py-2 font-normal bg-transparent border-0 rounded-lg hover:text-slate-700 hover:bg-gray-200 text-slate-500 text-align-inherit whitespace-nowrap lg:transition-colors lg:duration-300 lg:ease-soft">
                                        <div class="flex">
                                            <div class="flex mt-1 mr-4 text-black fill-current h-4 w-4 stroke-none">
                                                <svg class="text-slate-400" width="16px" height="16px" viewBox="0 0 40 40"
                                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                    <title>spaceship</title>
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF"
                                                            fill-rule="nonzero">
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g transform="translate(4.000000, 301.000000)">
                                                                    <path class="fill-slate-300"
                                                                        d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z">
                                                                    </path>
                                                                    <path class="fill-slate-300"
                                                                        d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z">
                                                                    </path>
                                                                    <path class="fill-slate-300"
                                                                        d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z"
                                                                        opacity="0.598539807">
                                                                    </path>
                                                                    <path class="fill-slate-300"
                                                                        d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z"
                                                                        opacity="0.598539807">
                                                                    </path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div>
                                                <h6
                                                    class="flex p-0 mb-0 font-bold text-sm whitespace-nowrap text-slate-700">
                                                    Проекты</h6>
                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                                <li class="relative block p-0 bg-white border-0">
                                    <router-link to="/Users/Index" @click="openMenu = false"
                                        class="block clear-both w-full px-4 py-2 font-normal bg-transparent border-0 rounded-lg hover:text-slate-700 hover:bg-gray-200 text-slate-500 text-align-inherit whitespace-nowrap lg:transition-colors lg:duration-300 lg:ease-soft">
                                        <div class="flex">
                                            <div class="flex mt-1 mr-4 text-black fill-current h-4 w-4 stroke-none">
                                                <svg class="text-slate-400" width="16px" height="16px" viewBox="0 0 40 44"
                                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                    <title>document</title>
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF"
                                                            fill-rule="nonzero">
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g transform="translate(154.000000, 300.000000)">
                                                                    <path class="fill-slate-300"
                                                                        d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                                                                        opacity="0.603585379">
                                                                    </path>
                                                                    <path class="fill-slate-300"
                                                                        d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z">
                                                                    </path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div>
                                                <h6
                                                    class="flex p-0 mb-0 font-bold text-sm whitespace-nowrap text-slate-700">
                                                    Участники</h6>
                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                                <li class="relative block p-0 bg-white border-0">
                                    <router-link to="/News/Index" @click="openMenu = false"
                                        class="block clear-both w-full px-4 py-2 font-normal bg-transparent border-0 rounded-lg hover:text-slate-700 hover:bg-gray-200 text-slate-500 text-align-inherit whitespace-nowrap lg:transition-colors lg:duration-300 lg:ease-soft">
                                        <div class="flex">
                                            <div class="flex mt-1 mr-4 text-black fill-current h-4 w-4 stroke-none">
                                                <svg class="text-slate-400" width="16px" height="16px" viewBox="0 0 42 42"
                                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                    <title>box-3d-50</title>
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <g transform="translate(-2319.000000, -291.000000)" fill="#FFFFFF"
                                                            fill-rule="nonzero">
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g transform="translate(603.000000, 0.000000)">
                                                                    <path class="fill-slate-300"
                                                                        d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z">
                                                                    </path>
                                                                    <path class="fill-slate-300"
                                                                        d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                                                                        opacity="0.7"></path>
                                                                    <path class="fill-slate-300"
                                                                        d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                                                                        opacity="0.7"></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div>
                                                <h6
                                                    class="flex p-0 mb-0 font-bold text-sm whitespace-nowrap text-slate-700">
                                                    Новости</h6>

                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                                <li class="relative block p-0 bg-white border-0">
                                    <router-link to="/Publications/Create" @click="openMenu = false"
                                        class="block clear-both w-full px-4 py-2 font-normal bg-transparent border-0 rounded-lg hover:text-slate-700 hover:bg-gray-200 text-slate-500 text-align-inherit whitespace-nowrap lg:transition-colors lg:duration-300 lg:ease-soft">
                                        <div class="flex">
                                            <div class="flex mt-1 mr-4 text-black fill-current h-4 w-4 stroke-none">
                                                <svg class="text-slate-400" width="16px" height="16px" viewBox="0 0 40 44"
                                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                    <title>switches</title>
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <g transform="translate(-1870.000000, -440.000000)" fill="#FFFFFF"
                                                            fill-rule="nonzero">
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g transform="translate(154.000000, 149.000000)">
                                                                    <path class="fill-slate-300"
                                                                        d="M10,20 L30,20 C35.4545455,20 40,15.4545455 40,10 C40,4.54545455 35.4545455,0 30,0 L10,0 C4.54545455,0 0,4.54545455 0,10 C0,15.4545455 4.54545455,20 10,20 Z M10,3.63636364 C13.4545455,3.63636364 16.3636364,6.54545455 16.3636364,10 C16.3636364,13.4545455 13.4545455,16.3636364 10,16.3636364 C6.54545455,16.3636364 3.63636364,13.4545455 3.63636364,10 C3.63636364,6.54545455 6.54545455,3.63636364 10,3.63636364 Z"
                                                                        opacity="0.6"></path>
                                                                    <path class="fill-slate-300"
                                                                        d="M30,23.6363636 L10,23.6363636 C4.54545455,23.6363636 0,28.1818182 0,33.6363636 C0,39.0909091 4.54545455,43.6363636 10,43.6363636 L30,43.6363636 C35.4545455,43.6363636 40,39.0909091 40,33.6363636 C40,28.1818182 35.4545455,23.6363636 30,23.6363636 Z M30,40 C26.5454545,40 23.6363636,37.0909091 23.6363636,33.6363636 C23.6363636,30.1818182 26.5454545,27.2727273 30,27.2727273 C33.4545455,27.2727273 36.3636364,30.1818182 36.3636364,33.6363636 C36.3636364,37.0909091 33.4545455,40 30,40 Z">
                                                                    </path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
      
                                        </div>
                                    </router-link>
                                </li>
                            </ul>

                        </details>

                        <details class="group ">
                            <summary
                                class="flex cursor-pointer items-center justify-between rounded-lg px-2 whitespace-nowrap">
                                <h2 class="font-medium text-gray-900">
                                    Ближайшие мероприятия
                                </h2>

                                <svg class="ml-1.5 h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-180"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M19 9l-7 7-7-7" />
                                </svg>
                            </summary>
                            <ul class="flex flex-col pl-0 mx-auto mb-0 list-none pt-2">
                                <li class="relative mx-2 md-max:static group ">
                                    <div nav-dropdown="applications"
                                        class="sm-max:right-auto md-max:left-0 md-max:right-0 lg-max:bg-transparent lg-max:overflow-scroll lg-max:relative lg-max:shadow-none lg-max:transform-none lg-max:block lg-max:h-0 lg-max:transition-all lg-max:duration-350 lg-max:ease-soft lg-max:pt-0 lg-max:pb-0 lg-max:opacity-0 lg-max:top-0 lg-max:origin-top lg-max:pointer-events-none  static m-0 mt-0 text-left list-none bg-white rounded-2xl lg:mt-4 lg:min-w-160 lg:absolute z-100 text-sm text-slate-500 bg-clip-padding lg:shadow-soft-3xl lg:cursor-pointer lg:transition-all lg:duration-250 lg:block lg:group-focus:pointer-events-auto lg:group-focus:transform-dropdown-show lg:group-focus:opacity-100 lg:group-focus:before:-top-5 lg:group-hover:opacity-100 lg:opacity-0  lg:origin-top lg:group-hover:pointer-events-auto lg:group-hover:transform-dropdown-show lg:pointer-events-none lg:transform-dropdown lg:before:font-awesome lg:before:content-['\f0d8'] lg:before:absolute lg:before:top-0 lg:group-hover:before:-top-4.8  lg:before:left-7 lg:before:right-auto lg:before:text-5.5 lg:before:text-white lg:before:transition-all lg:before:duration-350 lg:before:ease-soft">
                                        <div class="w-full max-w-full ">
                                            <div class="relative flex flex-col min-w-0 break-wordsborder-0  ">
                                                <div class="flex-auto rounded-xl">
                                                    <div class="flex">
                                                        <div
                                                            class="my-auto inline-block w-8 h-8 text-center text-black bg-center rounded-lg shadow-none fill-current stroke-none bg-blue-600/5">
                                                            <img class="h-8 w-8 p-1 my-auto object-fit mx-auto"
                                                                src="@/assets/img/ForPage/billboard.png" alt="">

                                                        </div>
                                                        <div class="ml-2">
                                                            <div class="">
                                                                <h6
                                                                    class="flex p-0 mb-0 font-bold text-sm whitespace-nowrap text-slate-700">
                                                                    Cyber Week</h6>
                                                                <span class="leading-normal text-sm whitespace-nowrap">27
                                                                    March 2020, at 12:30
                                                                    PM</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex my-4">
                                                        <div
                                                            class="my-auto inline-block w-8 h-8 text-center text-black bg-center rounded-lg shadow-none fill-current stroke-none bg-blue-600/5">
                                                            <img class="h-8 w-8 p-1 mx-auto my-auto rounded-lg "
                                                                src="@/assets/img/ForPage/calendar.png" alt="">
                                                        </div>
                                                        <div class="ml-2">
                                                            <div class="">
                                                                <h6
                                                                    class="flex p-0 mb-0 font-bold text-sm whitespace-nowrap text-slate-700">
                                                                    Meeting with Marry</h6>
                                                                <span class="leading-normal text-sm">24 March 2021, at
                                                                    10:00
                                                                    PM</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </details>

                        <ul class=" pl-0 mb-0 pt-2 list-none lg:block lg:flex-row">
                            <li
                                class="relative mx-2 md-max:static group lg:after:content-[''] lg:after:top-0 lg:hover:after:top-full lg:after:absolute lg:after:left-0 lg:after:-bottom-6 lg:after:w-full lg:after:h-full">
                                <div v-if="this.LogginUser?.id != null">
                                    <div aria-expanded="false" class="flex shrink-0 items-center text-start">
                                        <img alt="Man"
                                            src="https://images.unsplash.com/photo-1600486913747-55e5470d6f40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                                            class="h-8 w-8 rounded-full object-cover" />

                                        <div class="ml-1.5">
                                            <p class="text-xs">
                                                <strong class="block font-medium">Eric Frusciante</strong>

                                                <span> eric@frusciante.com </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="flex">
                                    <router-link to="/Login"
                                        class="block  py-2 font-semibold transition-all ease-nav-brand text-sm text-slate-500 ">
                                        <i class="fa fa-user sm:mr-1 pr-1" aria-hidden="true"></i>
                                        <span class="hidden sm:inline">Войти</span>
                                    </router-link>

                                </div>

                            </li>



                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div :onscroll="onScroll" class="text-black bg-slate-100 min-h-screen overflow-y-hidden">
            <router-view />
        </div>
    </div>
</template>

<script>
import "../node_modules/flowbite/dist/flowbite";
// import "@/assets/css/soft-ui-dashboard-tailwind.css"
// import "@/assets/js/soft-ui-dashboard-tailwind.js"
import 'tw-elements';

// import './assets/css/app.css'
//import CircleUser from '@/components/Resourse/StylesObj/CircleUser.vue'
export default {
    name: 'App',
    data() {
        return {
            width: 1000,
            showHeader: false,
            isScroll: false,
            openMenu: false,
            isTransparent: false,

        }
    },

    mounted() {
        this.width = window.innerWidth;
        window.addEventListener('scroll', this.handleScroll);

    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    created() {
        window.addEventListener('resize', this.updateWidth);
    },

    components: {
    },
    methods: {
        showKanbanVoard(){
            console.log(this.LogginUser)
            if(this.LogginUser?.role?.name == "Админ" || this.LogginUser?.role?.name == "Наставник"){
                return true
            }
            return false
            
        },
        windowWidth() {
            this.width = window.innerWidth
            return this.width
        },
        handleScroll() {
            const fixedSection = this.$refs.fixedSection;
            const top = fixedSection.getBoundingClientRect().top;
            if (top < -10) {
                this.isTransparent = true;
            } else {
                this.isTransparent = false;
            }
        },
        updateWidth() {
            this.width = window.innerWidth;
        },

    }
}
</script>
<style src="@vueform/multiselect/themes/default.css">
details>summary {
    list-style: none;
}

details>summary::-webkit-details-marker {
    display: none;
}
</style>

<style></style>
